import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function CreditsIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props} viewBox='0 0 52 45'>
			<g>
				<path d="M38.5 1h-7.87l-3.241 5.136h-9.722L1 35.083h19.907l6.945-10.271h11.574l-2.315 3.735H30.63L19.982 44.42h7.87l6.018-9.805h7.408L51 18.275H24.148l-6.481 10.272h-4.63l9.26-15.875h8.333L38.5 1z" />
			</g>
		</SvgIcon>
	)
}

export function ServiceRecordIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props}>
			<g>
				<path d="M8.53,19.41c-0.35-0.17-0.68-0.37-1-0.57c0.03-0.09,0.08-0.23,0.13-0.4c0.1-0.34,0.2-0.82,0.27-1.36
					c0.06-0.55-0.2-0.99-0.46-1.33c-0.06-0.09-0.13-0.16-0.2-0.23c-0.06-0.07-0.11-0.13-0.16-0.18c-0.1-0.1-0.17-0.16-0.17-0.16
					s-0.03,0-0.14,0.17c-0.11,0.17-0.33,0.5-0.62,1.25c-0.18,0.47-0.12,0.91,0.05,1.27c-0.43-0.38-0.83-0.79-1.18-1.24
					c0.06-0.07,0.16-0.19,0.29-0.37c0.21-0.29,0.46-0.71,0.72-1.19c0.25-0.49,0.17-0.95,0.09-1.31c-0.02-0.09-0.05-0.17-0.08-0.25
					c-0.02-0.08-0.04-0.15-0.06-0.2c-0.04-0.11-0.09-0.18-0.09-0.18s-0.02-0.01-0.2,0.09c-0.17,0.11-0.52,0.3-1.09,0.86
					C4.34,14.35,4.2,14.68,4.17,15c0,0.1,0,0.21,0.01,0.31c-0.15-0.29-0.29-0.6-0.42-0.92c-0.09-0.23-0.16-0.47-0.22-0.71
					c0.08-0.04,0.23-0.11,0.44-0.23c0.31-0.18,0.74-0.44,1.16-0.76c0.45-0.32,0.58-0.71,0.66-1.16c0.02-0.21,0.06-0.4,0.06-0.55
					c0.01-0.14,0-0.24,0-0.24s-0.02-0.02-0.22,0.01c-0.2,0.03-0.59,0.1-1.35,0.39c-0.38,0.15-0.62,0.39-0.79,0.67
					c-0.08,0.13-0.13,0.27-0.17,0.41c-0.03-0.62,0.01-1.26,0.12-1.89c0.08,0,0.27-0.01,0.53-0.04c0.36-0.05,0.84-0.14,1.36-0.31
					C5.62,9.92,5.83,9.77,5.99,9.6c0.18-0.17,0.3-0.38,0.41-0.58C6.6,8.63,6.72,8.25,6.72,8.25S6.71,8.23,6.5,8.2
					C6.3,8.17,5.88,8.12,5.09,8.17C4.69,8.2,4.36,8.35,4.12,8.56C4,8.67,3.89,8.79,3.81,8.92C4,8.3,4.25,7.7,4.54,7.13
					C4.62,7.15,4.8,7.2,5.05,7.25c0.35,0.06,0.85,0.11,1.4,0.1c0.56-0.01,0.98-0.36,1.3-0.68c0.31-0.32,0.5-0.62,0.43-0.54
					c0,0-0.01-0.02-0.15-0.16c-0.17-0.11-0.52-0.29-1.3-0.47C6.16,5.36,5.68,5.55,5.32,5.84C5.62,5.41,5.95,5,6.29,4.64
					c0.68,0,1.67-0.18,2.21-1.11c0.88-1.5,0.79-1.58,0.79-1.58s-2.09-0.3-2.84,0.7C6.17,3.03,5.97,3.39,5.84,3.71c0,0,0,0,0,0
					C5.4,4.14,5,4.62,4.64,5.13c0.11-0.42,0.14-0.89-0.02-1.4c-0.25-0.82-0.33-1.27-0.39-1.5C4.21,1.97,4.18,1.98,4.17,1.98
					C4.1,2.05,3.59,2.47,3.18,3.08c-0.42,0.6-0.73,1.39-0.53,1.99c0.2,0.61,0.49,1.02,0.73,1.29C3.52,6.52,3.63,6.62,3.7,6.68
					C3.41,7.26,3.16,7.87,2.97,8.49c0-0.11,0-0.23-0.02-0.35C2.9,7.79,2.8,7.43,2.57,7.07C2.09,6.35,1.88,5.97,1.74,5.79
					C1.61,5.61,1.58,5.63,1.58,5.63S1.23,6.14,1,6.83C0.88,7.18,0.81,7.58,0.8,7.94c0,0.38,0.07,0.73,0.24,0.99
					c0.37,0.53,0.77,0.85,1.08,1.04c0.21,0.13,0.36,0.19,0.45,0.22c-0.11,0.68-0.15,1.36-0.13,2.04c-0.05-0.14-0.11-0.29-0.17-0.43
					c-0.14-0.32-0.38-0.64-0.71-0.91c-0.65-0.54-0.99-0.85-1.17-0.98c-0.18-0.14-0.2-0.11-0.2-0.11s-0.04,0.15-0.08,0.4
					C0.06,10.46-0.01,10.81,0,11.19c-0.01,0.73,0.21,1.69,0.74,2.08c0.55,0.4,1.04,0.54,1.39,0.6c0.22,0.03,0.38,0.04,0.48,0.03
					c0.13,0.62,0.34,1.22,0.61,1.78c-0.06-0.05-0.12-0.11-0.19-0.16c-0.28-0.22-0.61-0.43-1.04-0.54c-0.83-0.24-1.26-0.41-1.48-0.46
					c-0.22-0.06-0.23-0.03-0.23-0.03s0.03,0.17,0.09,0.44c0.03,0.13,0.07,0.29,0.12,0.46c0.06,0.17,0.14,0.34,0.22,0.53
					c0.33,0.74,0.94,1.45,1.58,1.57c0.64,0.12,1.16,0.02,1.5-0.07c0.2-0.06,0.34-0.12,0.43-0.16c0.43,0.53,0.92,1.02,1.44,1.46
					c-0.44-0.2-0.96-0.34-1.56-0.25c-0.85,0.12-1.3,0.16-1.52,0.2c-0.22,0.04-0.22,0.07-0.22,0.07s0.09,0.14,0.24,0.34
					c0.08,0.1,0.17,0.22,0.28,0.35c0.11,0.12,0.24,0.25,0.38,0.38c0.55,0.51,1.33,0.94,1.96,0.82c0.64-0.12,1.09-0.37,1.39-0.58
					c0.22-0.16,0.35-0.29,0.41-0.35c0.32,0.2,0.65,0.38,0.98,0.55C8.59,20.53,9.08,19.69,8.53,19.41z"/>
				<path d="M23.47,14.52c-0.22,0.05-0.65,0.23-1.48,0.47c-0.43,0.11-0.75,0.31-1.04,0.54c-0.13,0.09-0.24,0.2-0.34,0.31
					c0.27-0.52,0.51-1.07,0.69-1.65c0.03-0.09,0.05-0.19,0.07-0.28c0.1,0,0.26,0,0.48-0.03c0.35-0.06,0.85-0.2,1.39-0.6
					c0.53-0.39,0.76-1.35,0.74-2.08c0.02-0.38-0.05-0.72-0.09-0.97c-0.04-0.25-0.08-0.4-0.08-0.4s-0.02-0.02-0.2,0.11
					c-0.18,0.13-0.52,0.44-1.17,0.98c-0.33,0.27-0.57,0.59-0.71,0.91c-0.03,0.07-0.06,0.15-0.09,0.22c0.01-0.62-0.04-1.24-0.15-1.86
					c0.09-0.04,0.22-0.1,0.38-0.2c0.31-0.19,0.71-0.5,1.08-1.04c0.17-0.26,0.24-0.61,0.24-0.99C23.19,7.58,23.12,7.18,23,6.83
					c-0.23-0.7-0.58-1.21-0.58-1.21s-0.03-0.01-0.16,0.17c-0.14,0.18-0.35,0.55-0.82,1.27c-0.24,0.36-0.34,0.72-0.39,1.07
					c-0.01,0.08-0.01,0.16-0.02,0.23c-0.2-0.58-0.45-1.16-0.73-1.7c0.08-0.06,0.19-0.17,0.32-0.31c0.24-0.27,0.53-0.69,0.73-1.29
					c0.19-0.6-0.12-1.39-0.53-1.99c-0.41-0.61-0.92-1.03-0.99-1.09c0,0-0.04-0.01-0.06,0.25c-0.05,0.23-0.14,0.68-0.39,1.5
					c-0.16,0.52-0.13,0.99-0.02,1.41c-0.37-0.52-0.78-1.01-1.21-1.43l0,0c-0.13-0.31-0.33-0.68-0.61-1.06c-0.75-1-2.84-0.7-2.84-0.7
					s-0.09,0.08,0.79,1.58c0.54,0.93,1.54,1.11,2.21,1.11c0.35,0.38,0.68,0.78,0.98,1.21c-0.36-0.29-0.85-0.49-1.43-0.36
					c-0.78,0.18-1.13,0.36-1.3,0.47c-0.14,0.14-0.14,0.16-0.15,0.16c-0.07-0.07,0.12,0.22,0.43,0.54c0.32,0.32,0.74,0.67,1.3,0.68
					c0.56,0.01,1.05-0.04,1.4-0.1c0.25-0.04,0.42-0.09,0.5-0.11c0.28,0.56,0.52,1.13,0.7,1.73c-0.08-0.11-0.17-0.21-0.27-0.3
					c-0.24-0.22-0.57-0.36-0.97-0.39c-0.79-0.05-1.21,0-1.41,0.03c-0.21,0.03-0.22,0.05-0.22,0.05s0.11,0.37,0.32,0.76
					c0.11,0.2,0.23,0.4,0.41,0.58c0.16,0.18,0.37,0.33,0.64,0.41c0.52,0.16,1.01,0.26,1.36,0.31c0.22,0.03,0.38,0.04,0.48,0.04
					c0.09,0.58,0.14,1.16,0.12,1.74c-0.03-0.09-0.07-0.17-0.12-0.26c-0.18-0.28-0.42-0.52-0.79-0.67c-0.76-0.29-1.14-0.35-1.35-0.39
					c-0.2-0.03-0.22-0.01-0.22-0.01s-0.01,0.09,0,0.24c0,0.14,0.04,0.34,0.06,0.55c0.08,0.44,0.21,0.84,0.66,1.16
					c0.43,0.31,0.85,0.58,1.16,0.75c0.19,0.11,0.33,0.18,0.41,0.22c-0.02,0.08-0.03,0.17-0.05,0.25c-0.13,0.54-0.33,1.04-0.58,1.52
					c0.03-0.15,0.04-0.3,0.03-0.45c-0.03-0.32-0.17-0.65-0.44-0.92c-0.57-0.56-0.92-0.75-1.09-0.86c-0.18-0.1-0.2-0.09-0.2-0.09
					s-0.04,0.07-0.08,0.18c-0.02,0.06-0.05,0.13-0.06,0.2c-0.03,0.08-0.06,0.16-0.08,0.25c-0.08,0.36-0.16,0.83,0.09,1.31
					c0.26,0.49,0.5,0.9,0.72,1.19c0.14,0.2,0.25,0.33,0.31,0.4c-0.37,0.46-0.78,0.87-1.23,1.25c0.19-0.37,0.27-0.82,0.08-1.32
					c-0.29-0.75-0.51-1.08-0.62-1.25c-0.12-0.17-0.14-0.17-0.14-0.17S17,15.24,16.9,15.34c-0.05,0.05-0.11,0.11-0.16,0.18
					c-0.06,0.07-0.13,0.14-0.2,0.23c-0.26,0.34-0.53,0.78-0.46,1.33c0.06,0.54,0.17,1.02,0.27,1.36c0.05,0.18,0.1,0.31,0.13,0.41
					c-0.33,0.2-0.66,0.39-1,0.56c-0.56,0.28-0.07,1.11,0.49,0.83c0.34-0.17,0.67-0.36,0.98-0.55c0.06,0.06,0.19,0.19,0.41,0.35
					c0.3,0.21,0.75,0.46,1.39,0.58c0.64,0.12,1.41-0.31,1.96-0.82c0.14-0.13,0.27-0.26,0.38-0.38c0.11-0.13,0.2-0.25,0.28-0.35
					c0.15-0.2,0.24-0.34,0.24-0.34s0-0.03-0.22-0.07c-0.22-0.05-0.67-0.08-1.52-0.2c-0.63-0.09-1.16,0.05-1.61,0.27
					c0.53-0.45,1.02-0.95,1.44-1.5c0.08,0.04,0.24,0.11,0.48,0.18c0.34,0.09,0.87,0.19,1.5,0.07c0.64-0.11,1.25-0.82,1.58-1.57
					c0.08-0.18,0.16-0.36,0.22-0.53c0.05-0.17,0.09-0.33,0.12-0.46c0.06-0.27,0.09-0.44,0.09-0.44S23.69,14.46,23.47,14.52z"/>
				<path d="M12,18.44c-0.92,0-1.66,0.74-1.66,1.66c0,0.92,0.74,1.66,1.66,1.66c0.92,0,1.66-0.74,1.66-1.66
					C13.66,19.18,12.92,18.44,12,18.44z"/>
				<path d="M16.26,10.17c0.13-0.09,0.1-0.17-0.05-0.17h-0.82h-0.94h-1.39l-0.77-2.38l0,0l-0.2-0.62c-0.05-0.15-0.13-0.15-0.18,0
					l-0.53,1.63l0,0L10.94,10H9.55H8.58H7.79c-0.16,0-0.18,0.08-0.05,0.17l1.46,1.06l1.09,0.79l-0.64,1.97l-0.33,1.02
					c-0.05,0.15,0.02,0.2,0.14,0.1l0.9-0.65l0,0l1.65-1.2l1,0.73l0,0l1.54,1.12c0.13,0.09,0.19,0.05,0.14-0.1l-0.58-1.78l-0.39-1.21
					l1.09-0.79v0L16.26,10.17z"/>
			</g>
		</SvgIcon>
	);
}

export function BestMatchIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props}>
			<path d="M22.47,1.6c-1.24-0.77-2.56-0.8-3.71-0.07c-0.34,0.22-0.64,0.5-0.91,0.82H12h-0.02H6.14
				c-0.26-0.32-0.57-0.6-0.91-0.82C4.09,0.8,2.77,0.82,1.53,1.6C0.4,2.3-0.17,3.67,0.05,5.17c0.33,2.26,2.23,4.21,5.2,5.41
				c0.33,1.35,0.86,2.6,1.73,3.53c0.4,0.42,0.77,0.79,1.12,1.11c0,0,0,0,0,0s0,0,0,0c0.19,0.17,0.37,0.33,0.54,0.47
				c0.45,0.47,1.06,1.32,1.29,2.63c0.13,0.92-0.64,1.29-1.67,1.29H7.16c-0.27,0-0.56,0.22-0.63,0.48l-0.66,2.43
				C5.8,22.78,5.97,23,6.24,23h4.34h2.03h5.33c0.27,0,0.44-0.22,0.37-0.48l-0.66-2.43c-0.07-0.26-0.35-0.48-0.63-0.48h-1.31
				c-1.02,0-1.8-0.36-1.67-1.29c0.24-1.31,0.84-2.15,1.29-2.63c0.17-0.14,0.36-0.3,0.54-0.47c0,0,0,0,0,0s0,0,0,0
				c0.35-0.32,0.72-0.68,1.12-1.11c0.87-0.93,1.41-2.18,1.73-3.52c2.98-1.2,4.89-3.15,5.22-5.42C24.17,3.67,23.6,2.3,22.47,1.6z
				M4.82,5.42C4.78,6.32,4.79,7.35,4.89,8.4C2.92,7.35,2.02,6,1.86,4.91C1.75,4.14,1.99,3.47,2.49,3.16c0.67-0.42,1.28-0.43,1.85-0.02
				c0.25,0.18,0.44,0.41,0.58,0.63c-0.04,0.38-0.07,0.82-0.09,1.3C4.8,5.18,4.8,5.3,4.82,5.42z M15.78,7.99l-1.82,1.32
				c-0.21,0.15-0.31,0.48-0.23,0.72l0.69,2.14c0.08,0.25-0.03,0.32-0.23,0.17l-1.81-1.32c-0.21-0.15-0.55-0.15-0.76,0L9.8,12.35
				c-0.21,0.15-0.31,0.08-0.23-0.17l0.69-2.14c0.08-0.25-0.03-0.57-0.23-0.72L8.21,7.99C8,7.84,8.04,7.72,8.3,7.72h2.24
				c0.26,0,0.53-0.2,0.61-0.45l0.69-2.14c0.08-0.25,0.21-0.25,0.29,0l0.69,2.14c0.08,0.25,0.36,0.45,0.61,0.45h2.24
				C15.95,7.72,15.99,7.84,15.78,7.99z M22.14,4.91c-0.16,1.1-1.06,2.45-3.05,3.5c0.1-1.03,0.11-2.04,0.08-2.94
				C19.21,5.31,19.2,5.15,19.15,5c-0.02-0.45-0.05-0.85-0.09-1.21c0.14-0.23,0.34-0.47,0.6-0.65c0.57-0.41,1.17-0.4,1.85,0.02
				C22.01,3.47,22.25,4.14,22.14,4.91z" />
		</SvgIcon>
	);
}

export function MapIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props}>
			<path d="M23.97,16.47l-1.6-12.69c-0.03-0.26-0.18-0.49-0.39-0.63C21.77,3,21.5,2.96,21.25,3.04l-6.54,1.91L8.85,3.29
				c-0.16-0.04-0.32-0.04-0.48,0L2.29,5.01C1.95,5.11,1.7,5.4,1.66,5.75l-1.6,12.69c-0.04,0.29,0.08,0.59,0.3,0.78
				c0.16,0.14,0.37,0.21,0.58,0.21c0.08,0,0.16-0.01,0.24-0.03l5.86-1.66l7.48,1.67c0.13,0.03,0.27,0.03,0.4,0l8.38-1.98
				C23.74,17.33,24.03,16.91,23.97,16.47z M21.2,8.63l-4.53,2.09l0.09-4.54l3.99-1.16L21.2,8.63z M8.61,5.06l5.87,1.66
				c0.16,0.04,0.33,0.04,0.49,0l0.91-0.27l-0.09,4.69l-1.63,0.75l-5.12-1.01L8.47,9.22C8.45,9.15,8.42,9.1,8.37,9.05
				c-1.1-1.14-2.28-2.4-2.89-3.1L8.61,5.06z M4.56,6.21c0.39,0.5,1.24,1.45,3.1,3.38l0.36,1.09l-0.04-0.01
				c-0.16-0.03-0.33-0.02-0.49,0.04L2.58,12.6l0.76-6.05L4.56,6.21z M7.01,15.95c-0.08,0-0.16,0.01-0.24,0.03l-4.79,1.36l0.35-2.74
				l5.57-2.13l0.77,0.15l1.32,3.98L7.2,15.97C7.14,15.95,7.07,15.95,7.01,15.95z M14.71,17.65l-3.72-0.83l-1.33-4.01l4.42,0.87
				c0.18,0.04,0.37,0.01,0.54-0.06l1.11-0.51l-0.08,4.32L14.71,17.65z M16.55,17.21l0.09-4.52l4.8-2.22l0.68,5.42L16.55,17.21z"/>
		</SvgIcon>
	);
}

export function MatchOutcomeIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props}>
			<g>
				<path d="M3.78,2C2.01,2,0.57,3.44,0.57,5.21c0,1.77,1.44,3.21,3.21,3.21C5.56,8.42,7,6.99,7,5.21C7,3.44,5.56,2,3.78,2z M4.27,6.67
					H3.44V4.72C3.31,4.82,3.18,4.9,3.06,4.97C2.93,5.03,2.78,5.09,2.59,5.15V4.48c0.28-0.09,0.49-0.2,0.65-0.32
					c0.15-0.13,0.27-0.28,0.36-0.46h0.67V6.67z"/>
				<path d="M3.78,9.17c-1.77,0-3.21,1.44-3.21,3.21c0,1.77,1.44,3.21,3.21,3.21C5.56,15.6,7,14.16,7,12.39
					C7,10.61,5.56,9.17,3.78,9.17z M4.93,13.85H2.48c0.03-0.24,0.11-0.47,0.26-0.68c0.14-0.21,0.41-0.46,0.8-0.75
					c0.24-0.18,0.39-0.31,0.46-0.4c0.07-0.09,0.1-0.18,0.1-0.26c0-0.09-0.03-0.17-0.1-0.23c-0.07-0.06-0.15-0.1-0.25-0.1
					c-0.1,0-0.19,0.03-0.26,0.1c-0.07,0.07-0.11,0.18-0.13,0.35L2.54,11.8c0.03-0.23,0.09-0.41,0.18-0.54c0.09-0.13,0.21-0.23,0.36-0.3
					c0.16-0.07,0.37-0.1,0.65-0.1c0.29,0,0.51,0.03,0.67,0.1c0.16,0.07,0.28,0.17,0.38,0.3c0.09,0.14,0.14,0.29,0.14,0.46
					c0,0.18-0.05,0.35-0.16,0.51c-0.1,0.16-0.3,0.34-0.57,0.54c-0.16,0.11-0.27,0.19-0.33,0.24c-0.06,0.05-0.12,0.1-0.2,0.18h1.27
					V13.85z"/>
				<path d="M3.78,16.35c-1.77,0-3.21,1.44-3.21,3.21c0,1.77,1.44,3.21,3.21,3.21c1.77,0,3.21-1.44,3.21-3.21
					C7,17.78,5.56,16.35,3.78,16.35z M4.81,20.58c-0.09,0.16-0.22,0.28-0.39,0.36c-0.17,0.08-0.39,0.13-0.66,0.13
					c-0.27,0-0.48-0.03-0.63-0.09c-0.15-0.06-0.28-0.15-0.38-0.27c-0.1-0.12-0.18-0.27-0.23-0.46l0.81-0.11
					c0.03,0.16,0.08,0.28,0.15,0.34c0.07,0.06,0.15,0.09,0.26,0.09c0.11,0,0.2-0.04,0.27-0.12c0.07-0.08,0.11-0.19,0.11-0.32
					c0-0.14-0.04-0.24-0.11-0.32c-0.07-0.07-0.17-0.11-0.29-0.11c-0.06,0-0.15,0.02-0.26,0.05l0.04-0.58c0.05,0.01,0.08,0.01,0.11,0.01
					c0.11,0,0.2-0.03,0.27-0.1c0.07-0.07,0.11-0.15,0.11-0.24c0-0.09-0.03-0.16-0.08-0.21c-0.05-0.05-0.13-0.08-0.22-0.08
					c-0.1,0-0.17,0.03-0.23,0.09c-0.06,0.06-0.1,0.16-0.12,0.31L2.56,18.8c0.06-0.25,0.19-0.43,0.37-0.56c0.18-0.13,0.44-0.2,0.77-0.2
					c0.38,0,0.66,0.07,0.83,0.21c0.17,0.14,0.26,0.32,0.26,0.54c0,0.13-0.03,0.24-0.1,0.34c-0.07,0.1-0.17,0.19-0.31,0.27
					c0.11,0.03,0.2,0.06,0.26,0.1c0.1,0.06,0.17,0.14,0.23,0.24c0.05,0.1,0.08,0.22,0.08,0.35C4.94,20.26,4.9,20.42,4.81,20.58z"/>
				<rect x="8.92" y="4.1" width="14.75" height="2.97"/>
				<rect x="8.92" y="10.9" width="11.57" height="2.97"/>
				<rect x="8.92" y="17.7" width="8.56" height="2.97"/>
			</g>
		</SvgIcon>
	);
}

export function MatchesIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props}>
			<g>
				<path d="M23.6,14.33C22.14,9.34,21.57,5,17.76,5H6.12c-3.81,0-4.38,4.34-5.85,9.33c-1.25,4.26,2,5.5,3.86,4.14
					c1.28-0.94,3.05-4.44,3.05-4.44h4.75h4.75c0,0,1.77,3.5,3.05,4.44C21.61,19.84,24.85,18.59,23.6,14.33z M8.62,9.76H7.17v1.37
					c0,0.31-0.26,0.55-0.58,0.55C6.26,11.69,6,11.44,6,11.13V9.76H4.55c-0.32,0-0.58-0.25-0.58-0.55c0-0.31,0.26-0.55,0.58-0.55H6V7.28
					c0-0.31,0.26-0.55,0.58-0.55c0.32,0,0.58,0.25,0.58,0.55v1.37h1.45c0.32,0,0.58,0.25,0.58,0.55C9.2,9.51,8.94,9.76,8.62,9.76z
					M15.38,9.97c-0.44,0-0.79-0.34-0.79-0.75c0-0.41,0.35-0.75,0.79-0.75c0.44,0,0.79,0.34,0.79,0.75
					C16.16,9.63,15.81,9.97,15.38,9.97z M17.37,11.85c-0.44,0-0.79-0.34-0.79-0.75c0-0.41,0.35-0.75,0.79-0.75
					c0.44,0,0.79,0.34,0.79,0.75C18.16,11.52,17.8,11.85,17.37,11.85z M17.37,8.08c-0.44,0-0.79-0.34-0.79-0.75
					c0-0.41,0.35-0.75,0.79-0.75c0.44,0,0.79,0.34,0.79,0.75C18.16,7.74,17.8,8.08,17.37,8.08z M19.36,9.97
					c-0.44,0-0.79-0.34-0.79-0.75c0-0.41,0.35-0.75,0.79-0.75c0.44,0,0.79,0.34,0.79,0.75C20.15,9.63,19.8,9.97,19.36,9.97z"/>
			</g>
		</SvgIcon>
	);
}

export function MedalsIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props}>
			<g>
				<path d="M12.23,11.57c-3.25,0-5.88,2.63-5.88,5.88c0,3.25,2.63,5.88,5.88,5.88c3.25,0,5.88-2.63,5.88-5.88
					C18.1,14.2,15.47,11.57,12.23,11.57z M11,16.89l-0.5-0.36l-0.82,0.59l-0.45,0.32c-0.06,0.05-0.1,0.02-0.07-0.05l0.16-0.51
					l0.32-0.98l-0.54-0.39l-0.72-0.53c-0.06-0.05-0.05-0.08,0.03-0.08h0.39h0.48h0.69l0.22-0.68l0.26-0.81c0.02-0.07,0.06-0.07,0.09,0
					l0.1,0.31l0.38,1.18h0.69h0.47h0.41c0.08,0,0.09,0.04,0.03,0.08l-0.72,0.53v0l-0.54,0.39l0.19,0.6l0.29,0.89
					c0.02,0.07-0.01,0.1-0.07,0.05L11,16.89z M16.06,18.2l-0.72,0.53v0l-0.54,0.39l0.19,0.6l0.29,0.89c0.02,0.07-0.01,0.1-0.07,0.05
					l-0.76-0.56l-0.5-0.36l-0.82,0.59l-0.45,0.32c-0.06,0.05-0.1,0.02-0.07-0.05l0.16-0.51l0.32-0.98l-0.54-0.39l-0.72-0.53
					c-0.06-0.05-0.05-0.08,0.03-0.08h0.39h0.48h0.69l0.22-0.68l0.26-0.81c0.02-0.07,0.06-0.07,0.09,0l0.1,0.31l0.38,1.18h0.69h0.47
					h0.41C16.11,18.11,16.12,18.15,16.06,18.2z"/>
			</g>
			<path d="M9.93,12.28V0H5.87v8.42l3.73,4.02 M14.85,12.43l3.73-4.02V0h-4.05v12.28 M12.23,14.3"/>
		</SvgIcon>
	);
}

export function ModesIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props}>
			<g>
				<path d="M0.92,2.63l8.05,19.22l1.6-0.87L1.7,2.24c0,0,0.32-0.51,0.24-0.78C1.86,1.18,1.82,1.01,1.5,0.93
					s-0.68-0.17-0.95,0.1S0.07,1.68,0.33,2.02S0.69,2.46,0.92,2.63z"/>
				<path d="M2.72,3c0,0,4.47,2.51,4.16,9.24c0.15-0.15,0.59-0.44,0.85-0.54s2.85-1.05,4.09-0.8
					c0.24,0.07,0.95,0.29,0.56,0.93c-0.39,0.65-0.71,1.6,0.88,1.51c1.6-0.08,2.56-0.12,10.61-3.23c-1.21-1.38-4.58-5.13-4.58-5.13
					S17.22,5.8,16.8,5.9c-0.42,0.1-3.28,0.9-2.44-0.95c0.42-0.65,0.59-0.78,0.56-0.97c-0.03-0.19-0.07-0.39-1.07-0.81
					C13.61,3.08,13.3,3,12.88,3S2.72,3,2.72,3z"/>
			</g>
		</SvgIcon>
	);
}

export function PatreonIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props}>
			<g>
				<rect x="0.1" y="1" width="4.34" height="22"/>
				<circle cx="15.47" cy="9.53" r="8.53"/>
			</g>
		</SvgIcon>
	);
}

export function PlaylistsIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props}>
			<g>
				<path d="M24,4.48v1.27c0,0.45-0.37,0.81-0.81,0.81H8.94c-0.45,0-0.81-0.37-0.81-0.81V4.48c0-0.45,0.37-0.81,0.81-0.81l14.25,0
					C23.63,3.67,24,4.03,24,4.48z"/>
				<path d="M24,11.96v1.27c0,0.45-0.37,0.81-0.81,0.81l-22.37,0C0.37,14.05,0,13.68,0,13.24l0-1.27c0-0.45,0.37-0.81,0.81-0.81h22.37
					C23.63,11.15,24,11.51,24,11.96z"/>
				<path d="M24,19.44v1.27c0,0.45-0.37,0.81-0.81,0.81H0.81C0.37,21.53,0,21.17,0,20.72l0-1.27c0-0.45,0.37-0.81,0.81-0.81h22.37
					C23.63,18.63,24,19,24,19.44z"/>
				<polygon points="6.28,5.12 0,1.49 0,8.74 	"/>
			</g>
		</SvgIcon>
	);
}

export function RankedIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props}>
			<g>
				<path d="M23.93,7.98c-0.07-0.07-0.18-0.09-0.28-0.06l-5.66,1.64c-0.28,0.08-0.47,0.31-0.47,0.57v1.32
					c0,0.97-0.9,1.76-2.02,1.76c-1.12,0-2.02-0.79-2.02-1.76c0-0.52,0.24-1.02,0.66-1.39l1.14-0.99c0.1-0.09,0.1-0.23,0-0.32
					l-3.09-2.69C12.14,6.02,12.07,6,12,6c-0.07,0-0.14,0.02-0.18,0.07L8.73,8.75c-0.1,0.09-0.1,0.23,0,0.32l1.14,0.99
					c0.42,0.37,0.66,0.87,0.66,1.39c0,0.97-0.9,1.76-2.02,1.76c-1.12,0-2.02-0.79-2.02-1.76v-1.32c0-0.26-0.19-0.49-0.47-0.57
					L0.34,7.92c-0.1-0.03-0.21,0-0.28,0.06C0,8.05-0.02,8.14,0.02,8.23l4.48,8.5H19.5l4.48-8.5C24.02,8.14,24,8.05,23.93,7.98z"/>
				<path d="M19.53,17.98c0-0.17-0.16-0.31-0.36-0.31H4.75c-0.2,0-0.36,0.14-0.36,0.31v0.63c0,0.17,0.16,0.31,0.36,0.31
					h14.42c0.2,0,0.36-0.14,0.36-0.31V17.98z"/>
			</g>
		</SvgIcon>
	);
}

export function SearchIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props}>
			<g>
				<g>
					<g>
						<path d="M8.68,0C4.04,0,0.29,3.76,0.29,8.39s3.76,8.39,8.39,8.39s8.39-3.76,8.39-8.39S13.31,0,8.68,0z M8.68,14.79
							c-3.53,0-6.4-2.86-6.4-6.4s2.86-6.4,6.4-6.4s6.4,2.86,6.4,6.4S12.21,14.79,8.68,14.79z"/>
					</g>
				</g>
				<path d="M23.58,23.09l-0.69,0.69c-0.32,0.32-0.84,0.32-1.15,0l-8.95-8.95c-0.32-0.32-0.32-0.84,0-1.15l0.69-0.69
					c0.32-0.32,0.84-0.32,1.15,0l8.95,8.95C23.9,22.26,23.9,22.78,23.58,23.09z"/>
			</g>
		</SvgIcon>
	);
}

export function SocialIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props}>
			<g>
				<path d="M6.89,13.54c-0.02-0.01-0.04-0.02-0.06-0.03c-0.05,0.03-0.11,0.07-0.17,0.1c-0.11,0.06-0.22,0.12-0.34,0.17
					c-0.42,0.17-0.86,0.26-1.32,0.26c-0.46,0-0.9-0.09-1.32-0.26c-0.12-0.05-0.23-0.11-0.34-0.17c-0.06-0.03-0.11-0.06-0.17-0.1
					c-1.53,0.69-2.6,2.21-2.6,3.98V18h4.95v-0.15v-0.49c0-0.84,0.17-1.65,0.5-2.42c0.2-0.46,0.44-0.88,0.74-1.28
					C6.82,13.62,6.86,13.58,6.89,13.54z"/>
				<polygon points="6.98,13.41 6.98,13.41 6.98,13.41 6.99,13.41 6.98,13.41 6.98,13.41 	"/>
				<path d="M3.57,12.65C3.57,12.65,3.57,12.65,3.57,12.65C3.56,12.65,3.56,12.65,3.57,12.65
					c0.07,0.06,0.15,0.11,0.23,0.15c0,0,0,0,0,0c0,0,0,0,0,0c0.15,0.09,0.32,0.16,0.49,0.22c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0
					c0,0,0.01,0,0.01,0c0.01,0,0.03,0.01,0.05,0.01c0.08,0.02,0.16,0.04,0.24,0.05c0.03,0,0.05,0.01,0.08,0.01
					c0.09,0.01,0.18,0.02,0.27,0.02c0.01,0,0.02,0,0.03,0c0.01,0,0.03,0,0.04,0c0.01,0,0.03,0,0.04,0c0.01,0,0.02,0,0.03,0
					c0.09,0,0.18-0.01,0.27-0.02c0.03,0,0.05-0.01,0.08-0.01c0.08-0.01,0.16-0.03,0.24-0.05c0.01,0,0.03-0.01,0.05-0.01
					c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0.08-0.02,0.15-0.05,0.22-0.08c0.09-0.04,0.18-0.08,0.27-0.13c0.08-0.05,0.16-0.1,0.23-0.15
					c0,0,0,0,0,0c0,0,0,0,0,0c0.61-0.44,1-1.15,1-1.95c0-1.34-1.1-2.43-2.45-2.43c-1.35,0-2.45,1.09-2.45,2.43
					C2.57,11.49,2.96,12.2,3.57,12.65z M6.23,12.8C6.24,12.8,6.24,12.8,6.23,12.8c-0.15,0.09-0.32,0.16-0.49,0.22c0,0,0,0,0,0
					C5.92,12.96,6.08,12.88,6.23,12.8z"/>
				<path d="M20.4,13.47c-0.05,0.03-0.11,0.07-0.17,0.1c-0.11,0.06-0.22,0.12-0.34,0.17C19.48,13.91,19.04,14,18.58,14
					c-0.46,0-0.9-0.09-1.32-0.26c-0.12-0.05-0.23-0.11-0.34-0.17c-0.06-0.03-0.11-0.06-0.17-0.1c-0.01,0-0.01,0.01-0.02,0.01
					c0.03,0.04,0.07,0.09,0.1,0.13c0.32,0.41,0.58,0.86,0.79,1.34c0.33,0.77,0.5,1.58,0.5,2.42v0.45v0.15H23v-0.51
					C23,15.68,21.93,14.16,20.4,13.47z"/>
				<path d="M16.15,10.96c0.01,0.08,0.03,0.17,0.05,0.25c0,0.01,0.01,0.03,0.01,0.04c0.02,0.08,0.04,0.16,0.07,0.23
					c0,0.01,0.01,0.02,0.01,0.03c0.01,0.03,0.02,0.05,0.03,0.08c0.12,0.29,0.3,0.55,0.53,0.77c0.09,0.09,0.18,0.16,0.28,0.24
					c0.07,0.05,0.15,0.1,0.23,0.15c0.09,0.05,0.18,0.1,0.27,0.13c0.07,0.03,0.15,0.06,0.22,0.08c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
					c0.01,0,0.03,0.01,0.05,0.01c0.08,0.02,0.16,0.04,0.24,0.05c0.03,0,0.05,0.01,0.08,0.01c0.09,0.01,0.18,0.02,0.27,0.02
					c0.01,0,0.02,0,0.03,0c0.01,0,0.03,0,0.04,0s0.03,0,0.04,0c0.01,0,0.02,0,0.03,0c0.09,0,0.18-0.01,0.27-0.02
					c0.03,0,0.05-0.01,0.08-0.01c0.08-0.01,0.16-0.03,0.24-0.05c0.01,0,0.03-0.01,0.04-0.01c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
					c0.08-0.02,0.15-0.05,0.22-0.08c0.09-0.04,0.18-0.08,0.27-0.13c0.08-0.05,0.16-0.1,0.23-0.15c0.1-0.07,0.19-0.15,0.28-0.24
					c0.23-0.22,0.4-0.48,0.53-0.77c0.01-0.03,0.02-0.05,0.03-0.08c0-0.01,0.01-0.02,0.01-0.03c0.03-0.08,0.05-0.15,0.07-0.23
					c0-0.01,0.01-0.03,0.01-0.04c0.02-0.08,0.04-0.16,0.05-0.25c0-0.01,0-0.02,0-0.03c0.01-0.1,0.02-0.19,0.02-0.29
					c0-0.33-0.06-0.65-0.19-0.95c-0.12-0.29-0.3-0.55-0.53-0.77c-0.23-0.22-0.49-0.4-0.78-0.52c-0.3-0.13-0.62-0.19-0.96-0.19
					s-0.65,0.06-0.96,0.19c-0.29,0.12-0.55,0.3-0.78,0.52c-0.23,0.22-0.4,0.48-0.53,0.77c-0.13,0.3-0.19,0.62-0.19,0.95
					c0,0.1,0.01,0.19,0.02,0.29C16.15,10.95,16.15,10.96,16.15,10.96z M19.8,12.75C19.8,12.75,19.8,12.75,19.8,12.75
					c-0.15,0.09-0.32,0.16-0.49,0.22c0,0,0,0,0,0C19.48,12.92,19.65,12.84,19.8,12.75z M17.13,12.6c0.07,0.05,0.15,0.1,0.23,0.15
					c0,0,0,0,0,0C17.28,12.71,17.2,12.66,17.13,12.6C17.13,12.6,17.13,12.6,17.13,12.6z"/>
				<path d="M16.01,13.9c-0.03-0.03-0.06-0.07-0.09-0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.07-0.08-0.14-0.15-0.21-0.23
					c-0.05-0.05-0.11-0.11-0.17-0.16c-0.42-0.39-0.91-0.71-1.45-0.95c-0.07,0.04-0.14,0.08-0.21,0.12c-0.14,0.08-0.27,0.15-0.42,0.21
					c-0.51,0.21-1.06,0.32-1.62,0.32c-0.56,0-1.11-0.11-1.62-0.32c-0.14-0.06-0.28-0.13-0.42-0.21c-0.07-0.04-0.14-0.08-0.21-0.12
					c-0.56,0.25-1.07,0.6-1.51,1.01c-0.06,0.05-0.11,0.11-0.16,0.16c-0.07,0.07-0.14,0.15-0.21,0.23c0,0,0,0,0,0c0,0,0,0,0,0
					c-0.03,0.03-0.06,0.07-0.09,0.1c-0.04,0.04-0.07,0.09-0.11,0.14c-0.7,0.91-1.11,2.04-1.11,3.26v0.49v0.14h3.03h0.04h0.01h0.01H9.8
					h0.33h3.35h0.33h0.31h0.01h0.01h3.16v-0.03v-0.15v-0.45c0-1.26-0.44-2.41-1.17-3.33C16.08,13.99,16.05,13.95,16.01,13.9z"/>
				<path d="M10.06,11.39C10.06,11.4,10.06,11.4,10.06,11.39C10.06,11.4,10.05,11.4,10.06,11.39
					c0.09,0.07,0.18,0.13,0.28,0.19c0.11,0.06,0.22,0.12,0.33,0.17c0.09,0.04,0.18,0.07,0.27,0.1c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
					c0.26,0.08,0.54,0.12,0.82,0.13c0.02,0,0.04,0,0.07,0c0.02,0,0.04,0,0.07,0c0.28-0.01,0.56-0.05,0.82-0.13c0,0,0.01,0,0.01,0
					c0,0,0.01,0,0.01,0c0.09-0.03,0.18-0.06,0.27-0.1c0.11-0.05,0.22-0.1,0.33-0.17c0.1-0.06,0.19-0.12,0.28-0.18c0,0,0,0,0,0
					c0,0,0,0,0,0c0.75-0.54,1.24-1.42,1.24-2.41c0-1.65-1.35-2.99-3.02-2.99c-1.67,0-3.02,1.34-3.02,2.99
					C8.82,9.98,9.31,10.85,10.06,11.39z M11.84,6.56c1.35,0,2.45,1.09,2.45,2.43c0,0.99-0.61,1.85-1.47,2.22c0,0,0,0,0,0c0,0,0,0,0,0
					c0.87-0.37,1.47-1.23,1.47-2.22C14.3,7.65,13.2,6.56,11.84,6.56c-1.35,0-2.45,1.09-2.45,2.43c0,0.99,0.61,1.85,1.47,2.22
					c0,0,0,0,0,0c0,0,0,0,0,0C10,10.84,9.39,9.98,9.39,8.99C9.39,7.65,10.49,6.56,11.84,6.56z"/>
			</g>
		</SvgIcon>
	);
}

export function SpartanCompanyIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props}>
			<g>
				<path d="M20.54,11.58V8.8l0.24-0.32V6.32c0,0-2.65-4.96-2.91-5.25C17.61,0.79,15.03,0,15.03,0l-0.67,0.45h-2.34H9.69L9.02,0
					c0,0-2.58,0.79-2.84,1.07C5.92,1.36,3.27,6.32,3.27,6.32v2.16L3.51,8.8v2.78l-1.45,2.33l0.57,2.36l7.75,7.11h1.65h1.65l7.75-7.11
					l0.57-2.36L20.54,11.58z M18.75,13.37c-0.81,0.64-3.7,3.27-3.7,3.27h-3.03H9c0,0-2.89-2.62-3.7-3.27
					c-0.69-1.72-0.26-3.58-0.26-3.58l2.31,1.53h2.12l0.43-0.93h2.12h2.12l0.43,0.93h2.12l2.31-1.53C19.02,9.79,19.45,11.65,18.75,13.37
					z"/>
			</g>
		</SvgIcon>
	);
}

export function VariantsIcon(props: SvgIconProps)
{
	return (
		<SvgIcon {...props}>
			<g>
				<path d="M13.05,19.94c0,0.78-0.63,1.41-1.41,1.41c-0.78,0-1.41-0.63-1.41-1.41c0-0.78,0.63-1.41,1.41-1.41
					C12.42,18.53,13.05,19.16,13.05,19.94L13.05,19.94z M12.29,12.44c-0.16-0.18-0.32-0.37-0.48-0.59c-0.06-0.09-0.12-0.17-0.18-0.26
					c-0.06,0.09-0.12,0.18-0.18,0.26c-0.17,0.23-0.35,0.44-0.52,0.63v4.84c-0.23,0.06-0.44,0.16-0.64,0.27
					c-0.8,0.47-1.34,1.34-1.34,2.34c0,1.5,1.22,2.71,2.71,2.71c1.5,0,2.71-1.22,2.71-2.71c0-1.03-0.58-1.93-1.43-2.39
					c-0.2-0.11-0.42-0.19-0.64-0.25V12.44L12.29,12.44z"/>
				<path d="M1.6,13.65c0-0.78,0.63-1.41,1.41-1.41s1.41,0.63,1.41,1.41c0,0.78-0.63,1.41-1.41,1.41S1.6,14.43,1.6,13.65L1.6,13.65z
					M9.16,13.15c0.3-0.19,1.09-0.7,1.79-1.66c0.38-0.51,0.7-1.14,0.93-1.81c0.42-1.2,0.4-2.09,0.38-3.86
					c-0.01-0.59-0.01-1.25-0.01-2.07c0-0.08,0-0.16,0-0.24l0.67,0.67l1.41,1.41c0.13,0.13,0.29,0.19,0.46,0.19s0.33-0.06,0.46-0.19
					c0.25-0.25,0.25-0.67,0-0.92l-2.38-2.38L11.6,1l-1.28,1.28L7.94,4.66c-0.25,0.25-0.25,0.67,0,0.92c0.25,0.25,0.67,0.25,0.92,0
					l1.41-1.41l0.68-0.68c0,0.08,0,0.16,0,0.24c0,0.83,0,1.5,0.01,2.09c0.02,1.72,0.03,2.44-0.31,3.42c-0.19,0.55-0.45,1.06-0.75,1.47
					c-0.56,0.77-1.21,1.18-1.45,1.33c-0.96,0.62-2,0.84-2.82,0.9c-0.06-0.21-0.14-0.41-0.24-0.6c-0.46-0.84-1.36-1.42-2.38-1.42
					c-1.5,0-2.71,1.22-2.71,2.71s1.22,2.71,2.71,2.71c1.05,0,1.97-0.6,2.42-1.49c0.1-0.2,0.18-0.4,0.23-0.62
					C6.65,14.19,7.94,13.92,9.16,13.15L9.16,13.15z"/>
				<path d="M20.24,15.08c-0.78,0-1.41-0.63-1.41-1.41c0-0.78,0.63-1.41,1.41-1.41s1.41,0.63,1.41,1.41
					C21.65,14.45,21.02,15.08,20.24,15.08L20.24,15.08z M20.24,10.96c-1.02,0-1.91,0.56-2.37,1.4c-0.1,0.19-0.19,0.39-0.24,0.6
					c-0.82-0.06-1.86-0.28-2.83-0.9c-0.24-0.15-0.88-0.57-1.45-1.33c-0.28-0.39-0.53-0.86-0.72-1.39c-0.05,0.18-0.1,0.36-0.17,0.55
					c-0.14,0.4-0.3,0.77-0.49,1.12c0.1,0.17,0.21,0.34,0.32,0.49c0.71,0.96,1.5,1.47,1.79,1.66c1.21,0.78,2.5,1.04,3.5,1.11
					c0.05,0.22,0.12,0.43,0.22,0.62c0.45,0.89,1.37,1.51,2.43,1.51c1.5,0,2.71-1.22,2.71-2.71C22.95,12.17,21.74,10.96,20.24,10.96
					L20.24,10.96z"/>
			</g>
		</SvgIcon>
	);
}

export function HaloDotAPIIcon(props: SvgIconProps)
{
	return <SvgIcon {... props}>
		<g><path fill="#f1f1f1" d="M 222.5,88.5 C 220.735,91.1226 220.068,94.1226 220.5,97.5C 217.462,113.961 211.129,128.961 201.5,142.5C 201.675,130.828 197.342,121.328 188.5,114C 196.644,101.214 202.144,87.3804 205,72.5C 207.693,54.7117 200.193,45.045 182.5,43.5C 161.434,46.3679 142.434,54.2012 125.5,67C 114.092,75.0495 103.425,84.0495 93.5,94C 92.552,94.4828 91.552,94.6495 90.5,94.5C 90.1489,91.7623 89.4822,89.0956 88.5,86.5C 90.829,80.3971 91.9957,74.0638 92,67.5C 116.223,45.8925 144.556,33.5592 177,30.5C 201.663,31.1693 216.663,43.5027 222,67.5C 222.5,74.4921 222.666,81.4921 222.5,88.5 Z"/></g>
		<g><path fill="#eaeaea" d="M 92.5,47.5 C 92.5,50.1667 92.5,52.8333 92.5,55.5C 89.6146,55.1936 86.9479,55.5269 84.5,56.5C 82.8267,57.343 81.66,58.6763 81,60.5C 80.8972,68.2441 80.2305,75.9108 79,83.5C 77.8906,85.8147 76.0573,87.3147 73.5,88C 77.1655,89.668 79.3322,92.5013 80,96.5C 80.0699,103.939 80.7365,111.272 82,118.5C 85.5828,120.29 89.4161,120.957 93.5,120.5C 93.7839,123.777 93.4505,126.943 92.5,130C 85.2699,130.555 78.2699,129.555 71.5,127C 69.4385,124.932 68.2718,122.432 68,119.5C 67.8972,111.756 67.2305,104.089 66,96.5C 62.6727,93.8461 58.8394,92.8461 54.5,93.5C 54.5,90.1667 54.5,86.8333 54.5,83.5C 58.8394,84.1539 62.6727,83.1539 66,80.5C 66.8648,71.3263 68.0315,62.3263 69.5,53.5C 70.9063,53.0269 71.573,52.0269 71.5,50.5C 72.842,49.207 74.5087,48.3736 76.5,48C 81.8229,47.5006 87.1563,47.334 92.5,47.5 Z"/></g>
		<g><path fill="#7e7e7e" d="M 71.5,50.5 C 71.573,52.0269 70.9063,53.0269 69.5,53.5C 69.427,51.9731 70.0937,50.9731 71.5,50.5 Z"/></g>
		<g><path fill="#6f6f6f" d="M 92.5,47.5 C 93.4767,50.2869 93.81,53.2869 93.5,56.5C 90.5,56.5 87.5,56.5 84.5,56.5C 86.9479,55.5269 89.6146,55.1936 92.5,55.5C 92.5,52.8333 92.5,50.1667 92.5,47.5 Z"/></g>
		<g><path fill="#888888" d="M 222.5,88.5 C 222.142,91.6888 221.475,94.6888 220.5,97.5C 220.068,94.1226 220.735,91.1226 222.5,88.5 Z"/></g>
		<g><path fill="#f2f2f2" d="M 162.5,148.5 C 163.167,151.5 163.833,154.5 164.5,157.5C 164.924,162.12 165.924,166.62 167.5,171C 165.319,176.628 164.152,182.461 164,188.5C 145.848,203.998 125.348,215.498 102.5,223C 85.4536,228.768 68.4536,228.768 51.5,223C 39.7847,217.618 31.9514,208.784 28,196.5C 24.3657,172.36 28.699,149.693 41,128.5C 44.9224,120.984 49.5891,113.984 55,107.5C 55.3333,112.833 55.6667,118.167 56,123.5C 56.4645,127.393 57.6312,131.06 59.5,134.5C 48.36,150.113 41.6933,167.447 39.5,186.5C 38.5287,196.111 42.1954,203.278 50.5,208C 62.4496,210.576 74.1163,209.576 85.5,205C 102.797,197.357 118.797,187.691 133.5,176C 143.338,166.994 153.005,157.828 162.5,148.5 Z"/></g>
		<g><path fill="#ececec" d="M 176.5,127.5 C 182.777,126.554 187.277,128.888 190,134.5C 190.805,143.236 191.805,151.903 193,160.5C 196.377,163.013 200.21,164.013 204.5,163.5C 204.817,166.572 204.484,169.572 203.5,172.5C 200.891,173.23 198.224,173.73 195.5,174C 193.667,174.5 192.5,175.667 192,177.5C 191.994,186.653 190.994,195.653 189,204.5C 187.048,206.894 184.548,208.394 181.5,209C 176.177,209.499 170.844,209.666 165.5,209.5C 165.5,206.5 165.5,203.5 165.5,200.5C 169.546,200.85 173.38,200.183 177,198.5C 178.146,190.885 178.812,183.218 179,175.5C 180.077,172.087 182.244,169.587 185.5,168C 183.413,167.208 181.579,166.041 180,164.5C 178.775,157.245 178.108,149.911 178,142.5C 177.994,139.736 176.828,137.736 174.5,136.5C 172.052,135.527 169.385,135.194 166.5,135.5C 166.5,132.833 166.5,130.167 166.5,127.5C 169.833,127.5 173.167,127.5 176.5,127.5 Z"/></g>
		<g><path fill="#5b5b5b" d="M 176.5,127.5 C 173.167,127.5 169.833,127.5 166.5,127.5C 166.5,130.167 166.5,132.833 166.5,135.5C 169.385,135.194 172.052,135.527 174.5,136.5C 171.5,136.5 168.5,136.5 165.5,136.5C 165.5,133.167 165.5,129.833 165.5,126.5C 169.371,126.185 173.038,126.518 176.5,127.5 Z"/></g>
		<g><path fill="#9d9d9d" d="M 162.5,148.5 C 162.56,147.957 162.893,147.624 163.5,147.5C 164.487,150.768 164.82,154.102 164.5,157.5C 163.833,154.5 163.167,151.5 162.5,148.5 Z"/></g>
	</SvgIcon>
}