import { MedalRarity, MedalType } from "../Pieces/Medal";

export const AllMedals = {
	3233952928: {
		name: "Killjoy",
		description: "End an enemy's killing spree",
		type: MedalRarity.Normal,
		category: MedalType.Skill,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3233952928.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3233952928.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3233952928.png"
		}
	},
	2780740615: {
		name: "Killing Spree",
		description: "Kill 5 enemies without dying",
		type: MedalRarity.Heoric,
		category: MedalType.Spree,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2780740615.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2780740615.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2780740615.png"
		}
	},
	4261842076: {
		name: "Killing Frenzy",
		description: "Kill 10 enemies without dying",
		type: MedalRarity.Legendary,
		category: MedalType.Spree,
		sort: 1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4261842076.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4261842076.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/4261842076.png"
		}
	},
	418532952: {
		name: "Running Riot",
		description: "Kill 15 enemies without dying",
		type: MedalRarity.Mythic,
		category: MedalType.Spree,
		sort: 2,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/418532952.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/418532952.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/418532952.png"
		}
	},
	1486797009: {
		name: "Rampage",
		description: "Kill 20 enemies without dying",
		type: MedalRarity.Mythic,
		category: MedalType.Spree,
		sort: 3,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1486797009.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1486797009.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1486797009.png"
		}
	},
	710323196: {
		name: "Nightmare",
		description: "Kill 25 enemies without dying",
		type: MedalRarity.Mythic,
		category: MedalType.Spree,
		sort: 4,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/710323196.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/710323196.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/710323196.png"
		}
	},
	1720896992: {
		name: "Boogeyman",
		description: "Kill 30 enemies without dying",
		type: MedalRarity.Mythic,
		category: MedalType.Spree,
		sort: 5,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1720896992.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1720896992.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1720896992.png"
		}
	},
	2567026752: {
		name: "Grim Reaper",
		description: "Kill 35 enemies without dying",
		type: MedalRarity.Mythic,
		category: MedalType.Spree,
		sort: 6,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2567026752.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2567026752.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2567026752.png"
		}
	},
	2875941471: {
		name: "Demon",
		description: "Kill 40 enemies without dying",
		type: MedalRarity.Mythic,
		category: MedalType.Spree,
		sort: 7,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2875941471.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2875941471.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2875941471.png"
		}
	},
	1169390319: {
		name: "Steaktacular",
		description: "Win a game by dominating the enemy team",
		type: MedalRarity.Legendary,
		category: MedalType.GameEnd,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1169390319.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1169390319.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1169390319.png"
		}
	},
	1680000231: {
		name: "Flawless Victory",
		description: "Win every round of a game with 3+ rounds",
		type: MedalRarity.Legendary,
		category: MedalType.GameEnd,
		sort: 1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1680000231.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1680000231.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1680000231.png"
		}
	},
	865763896: {
		name: "Perfection",
		description: "Win a game with 15+ kills and no deaths",
		type: MedalRarity.Mythic,
		category: MedalType.GameEnd,
		sort: 2,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/865763896.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/865763896.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/865763896.png"
		}
	},
	2976102155: {
		name: "Action Hero",
		description: "Disarm the enemy bomb moments before it detonates",
		type: MedalRarity.Heoric,
		category: MedalType.Assault,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2976102155.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2976102155.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2976102155.png"
		}
	},
	1585298941: {
		name: "Assured Destruction",
		description: "Kill an enemy disarming your bomb moments before they succeed",
		type: MedalRarity.Heoric,
		category: MedalType.Assault,
		sort: 1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1585298941.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1585298941.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1585298941.png"
		}
	},
	1334138090: {
		name: "Late Boomer",
		description: "Plant the bomb moments before time expires",
		type: MedalRarity.Heoric,
		category: MedalType.Assault,
		sort: 2,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1334138090.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1334138090.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1334138090.png"
		}
	},
	3488248720: {
		name: "Stopped Short",
		description: "Kill an enemy flag carrier who is about to score",
		type: MedalRarity.Normal,
		category: MedalType.CTF,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3488248720.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3488248720.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3488248720.png"
		}
	},
	976049027: {
		name: "Flag Joust",
		description: "Kill an enemy flag carrier while holding a flag",
		type: MedalRarity.Normal,
		category: MedalType.CTF,
		sort: 1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/976049027.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/976049027.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/976049027.png"
		}
	},
	3227840152: {
		name: "Goal Line Stand",
		description: "Return a flag near the enemy flag stand",
		type: MedalRarity.Heoric,
		category: MedalType.CTF,
		sort: 2,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3227840152.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3227840152.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3227840152.png"
		}
	},
	4247875860: {
		name: "Duelist",
		description: "Win a 1v1 encounter in the final Elimination round",
		type: MedalRarity.Normal,
		category: MedalType.Elimination,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4247875860.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4247875860.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/4247875860.png"
		}
	},
	2623698509: {
		name: "Lone Wolf",
		description: "Win against 3+ enemies by yourself in an Elimination round",
		type: MedalRarity.Heoric,
		category: MedalType.Elimination,
		sort: 1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2623698509.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2623698509.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2623698509.png"
		}
	},
	3120600565: {
		name: "Immortal",
		description: "Survive every round of Elimination",
		type: MedalRarity.Legendary,
		category: MedalType.Elimination,
		sort: 2,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3120600565.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3120600565.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3120600565.png"
		}
	},
	521420212: {
		name: "Ace",
		description: "Wipe an enemy team in an Elimination round",
		type: MedalRarity.Legendary,
		category: MedalType.Elimination,
		sort: 3,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/521420212.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/521420212.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/521420212.png"
		}
	},
	3011158621: {
		name: "Necromancer",
		description: "Revive every ally in an Elimination round",
		type: MedalRarity.Legendary,
		category: MedalType.Elimination,
		sort: 4,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3011158621.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3011158621.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3011158621.png"
		}
	},
	4100966367: {
		name: "Extermination",
		description: "Wipe an enemy team with at least an Overkill in an Elimination round",
		type: MedalRarity.Mythic,
		category: MedalType.Elimination,
		sort: 5,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4100966367.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4100966367.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/4100966367.png"
		}
	},
	2426456555: {
		name: "Secure Line",
		description: "Kill an enemy converting your Extraction moments before they succeed",
		type: MedalRarity.Normal,
		category: MedalType.Extraction,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2426456555.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2426456555.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2426456555.png"
		}
	},
	3931425309: {
		name: "Signal Block",
		description: "Convert an enemy Extraction within moments of its completion",
		type: MedalRarity.Heoric,
		category: MedalType.Extraction,
		sort: 1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3931425309.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3931425309.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3931425309.png"
		}
	},
	2717755703: {
		name: "Sole Survivor",
		description: "Be the final Survivor in an Infection round",
		type: MedalRarity.Normal,
		category: MedalType.Infection,
		sort: -1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2717755703.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2717755703.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2717755703.png"
		}
	},
	1025827095: {
		name: "Culling",
		description: "Kill 2 Infected in quick succession",
		type: MedalRarity.Heoric,
		category: MedalType.Infection,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1025827095.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1025827095.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1025827095.png"
		}
	},
	88914608: {
		name: "Blight",
		description: "Kill 2 Survivors in quick succession",
		type: MedalRarity.Heoric,
		category: MedalType.Infection,
		sort: 6,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/88914608.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/88914608.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/88914608.png"
		}
	},
	557309779: {
		name: "Zombie Slayer",
		description: "Kill 10 Infected without dying",
		type: MedalRarity.Heoric,
		category: MedalType.Infection,
		sort: 12,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/557309779.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/557309779.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/557309779.png"
		}
	},
	3467301935: {
		name: "Purge",
		description: "Kill 4 Infected in quick succession",
		type: MedalRarity.Legendary,
		category: MedalType.Infection,
		sort: 1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3467301935.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3467301935.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3467301935.png"
		}
	},
	1155542859: {
		name: "Disease",
		description: "Kill 3 Survivors in quick succession",
		type: MedalRarity.Legendary,
		category: MedalType.Infection,
		sort: 7,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1155542859.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1155542859.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1155542859.png"
		}
	},
	1447057920: {
		name: "Undead Hunter",
		description: "Kill 20 Infected without dying",
		type: MedalRarity.Legendary,
		category: MedalType.Infection,
		sort: 13,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1447057920.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1447057920.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1447057920.png"
		}
	},
	1064731598: {
		name: "Untainted",
		description: "Maintain your humanity in Infection",
		type: MedalRarity.Legendary,
		category: MedalType.Infection,
		sort: 7,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1064731598.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1064731598.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1064731598.png"
		}
	},
	1765213446: {
		name: "Cleansing",
		description: "Kill 6 Infected in quick succession",
		type: MedalRarity.Mythic,
		category: MedalType.Infection,
		sort: 3,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1765213446.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1765213446.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1765213446.png"
		}
	},
	3786134933: {
		name: "Plague",
		description: "Kill 4 Survivors in quick succession",
		type: MedalRarity.Mythic,
		category: MedalType.Infection,
		sort: 8,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3786134933.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3786134933.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3786134933.png"
		}
	},
	217730222: {
		name: "Hell's Janitor",
		description: "Kill 30 Infected without dying",
		type: MedalRarity.Mythic,
		category: MedalType.Infection,
		sort: 14,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/217730222.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/217730222.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/217730222.png"
		}
	},
	17866865: {
		name: "The Sickness",
		description: "Infect all Survivors in an Infection round",
		type: MedalRarity.Mythic,
		category: MedalType.Infection,
		sort: 15,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/17866865.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/17866865.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/17866865.png"
		}
	},
	496411737: {
		name: "Purification",
		description: "Kill 8 Infected in quick succession",
		type: MedalRarity.Mythic,
		category: MedalType.Infection,
		sort: 4,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/496411737.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/496411737.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/496411737.png"
		}
	},
	1719203329: {
		name: "Pestilence",
		description: "Kill 5 Survivors in quick succession",
		type: MedalRarity.Mythic,
		category: MedalType.Infection,
		sort: 9,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1719203329.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1719203329.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1719203329.png"
		}
	},
	2164872967: {
		name: "Divine Intervention",
		description: "Kill 10 Infected in quick succession",
		type: MedalRarity.Mythic,
		category: MedalType.Infection,
		sort: 5,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2164872967.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2164872967.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2164872967.png"
		}
	},
	3520382976: {
		name: "Scourge",
		description: "Kill 6 Survivors in quick succession",
		type: MedalRarity.Mythic,
		category: MedalType.Infection,
		sort: 10,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3520382976.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3520382976.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3520382976.png"
		}
	},
	3653884673: {
		name: "Apocalypse",
		description: "Kill 7 Survivors in quick succession",
		type: MedalRarity.Mythic,
		category: MedalType.Infection,
		sort: 11,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3653884673.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3653884673.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3653884673.png"
		}
	},
	2005352812: {
		name: "Meganaut",
		description: "Recharge your shields 10 times as the Juggernaut",
		type: MedalRarity.Heoric,
		category: MedalType.Juggernaut,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2005352812.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2005352812.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2005352812.png"
		}
	},
	3732790338: {
		name: "Fumble",
		description: "Kill the enemy Oddball carrier within moments of their victory",
		type: MedalRarity.Heoric,
		category: MedalType.Oddball,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3732790338.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3732790338.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3732790338.png"
		}
	},
	781229683: {
		name: "Straight Balling",
		description: "Carry the Oddball for 1 minute",
		type: MedalRarity.Legendary,
		category: MedalType.Oddball,
		sort: 1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/781229683.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/781229683.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/781229683.png"
		}
	},
	1254180082: {
		name: "Deep Ball",
		description: "Complete a pass to a teammate from far away",
		type: MedalRarity.Normal,
		category: MedalType.Unknown,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1254180082.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1254180082.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1254180082.png"
		}
	},
	2362950720: {
		name: "Interception",
		description: "Catch an enemy-thrown ball near your goal",
		type: MedalRarity.Normal,
		category: MedalType.Unknown,
		sort: 1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2362950720.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2362950720.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2362950720.png"
		}
	},
	4014259917: {
		name: "Fast Break",
		description: "Score a goal shortly after the ball is spawned without a turnover",
		type: MedalRarity.Heoric,
		category: MedalType.Unknown,
		sort: 2,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4014259917.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4014259917.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/4014259917.png"
		}
	},
	1376646881: {
		name: "Great Journey",
		description: "Steal an enemy Power Seed and secure it for your team without dropping it",
		type: MedalRarity.Heoric,
		category: MedalType.Stockpile,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1376646881.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1376646881.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1376646881.png"
		}
	},
	629165579: {
		name: "Power Outage",
		description: "Steal 3 Power Seeds in quick succession",
		type: MedalRarity.Legendary,
		category: MedalType.Stockpile,
		sort: 1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/629165579.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/629165579.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/629165579.png"
		}
	},
	3528500956: {
		name: "All That Juice",
		description: "Secure 3 Power Seeds in quick succession",
		type: MedalRarity.Legendary,
		category: MedalType.Stockpile,
		sort: 2,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3528500956.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3528500956.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3528500956.png"
		}
	},
	1053114074: {
		name: "Clash of Kings",
		description: "Kill a VIP while being a VIP yourself",
		type: MedalRarity.Normal,
		category: MedalType.VIP,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1053114074.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1053114074.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1053114074.png"
		}
	},
	1210968206: {
		name: "Watch the Throne",
		description: "Save your VIP's life",
		type: MedalRarity.Normal,
		category: MedalType.VIP,
		sort: 1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1210968206.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1210968206.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1210968206.png"
		}
	},
	3945864962: {
		name: "Fast Lane",
		description: "As VIP, visit all Scout points within X seconds",
		type: MedalRarity.Heoric,
		category: MedalType.VIP,
		sort: 2,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3945864962.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3945864962.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3945864962.png"
		}
	},
	3041030832: {
		name: "High Value Target",
		description: "Kill a VIP that is one Scout Point away of scoring",
		type: MedalRarity.Heoric,
		category: MedalType.VIP,
		sort: 3,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3041030832.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3041030832.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3041030832.png"
		}
	},
	2125906504: {
		name: "Big Deal",
		description: "Survive as the VIP for 2 minutes",
		type: MedalRarity.Heoric,
		category: MedalType.VIP,
		sort: 4,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2125906504.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2125906504.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2125906504.png"
		}
	},
	590706932: {
		name: "Contract Killer",
		description: "Kill 3 enemy VIP's in a single life",
		type: MedalRarity.Legendary,
		category: MedalType.VIP,
		sort: 5,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/590706932.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/590706932.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/590706932.png"
		}
	},
	1472686630: {
		name: "Always Rotating",
		description: "Capture all zones in a single life",
		type: MedalRarity.Normal,
		category: MedalType.Zone,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1472686630.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1472686630.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1472686630.png"
		}
	},
	3630529364: {
		name: "Clock Stop",
		description: "Stop the enemy from scoring their final points by capturing a zone in Strongholds",
		type: MedalRarity.Heoric,
		category: MedalType.Zone,
		sort: 1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3630529364.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3630529364.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3630529364.png"
		}
	},
	622331684: {
		name: "Double Kill",
		description: "Kill 2 enemies in quick succession",
		type: MedalRarity.Heoric,
		category: MedalType.MultiKill,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/622331684.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/622331684.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/622331684.png"
		}
	},
	2063152177: {
		name: "Triple Kill",
		description: "Kill 3 enemies in quick succession",
		type: MedalRarity.Legendary,
		category: MedalType.MultiKill,
		sort: 1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2063152177.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2063152177.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2063152177.png"
		}
	},
	835814121: {
		name: "Overkill",
		description: "Kill 4 enemies in quick succession",
		type: MedalRarity.Mythic,
		category: MedalType.MultiKill,
		sort: 2,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/835814121.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/835814121.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/835814121.png"
		}
	},
	2137071619: {
		name: "Killtacular",
		description: "Kill 5 enemies in quick succession",
		type: MedalRarity.Mythic,
		category: MedalType.MultiKill,
		sort: 3,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2137071619.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2137071619.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2137071619.png"
		}
	},
	1430343434: {
		name: "Killtrocity",
		description: "Kill 6 enemies in quick succession",
		type: MedalRarity.Mythic,
		category: MedalType.MultiKill,
		sort: 4,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1430343434.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1430343434.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1430343434.png"
		}
	},
	3835606176: {
		name: "Killamanjaro",
		description: "Kill 7 enemies in quick succession",
		type: MedalRarity.Mythic,
		category: MedalType.MultiKill,
		sort: 5,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3835606176.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3835606176.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3835606176.png"
		}
	},
	2242633421: {
		name: "Killtastrophe",
		description: "Kill 8 enemies in quick succession",
		type: MedalRarity.Mythic,
		category: MedalType.MultiKill,
		sort: 6,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2242633421.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2242633421.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2242633421.png"
		}
	},
	3352648716: {
		name: "Killpocalypse",
		description: "Kill 9 enemies in quick succession",
		type: MedalRarity.Mythic,
		category: MedalType.MultiKill,
		sort: 7,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3352648716.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3352648716.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3352648716.png"
		}
	},
	3233051772: {
		name: "Killionaire",
		description: "Kill 10 enemies in quick succession",
		type: MedalRarity.Mythic,
		category: MedalType.MultiKill,
		sort: 8,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3233051772.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3233051772.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3233051772.png"
		}
	},
	2477555653: {
		name: "Spotter",
		description: "Mark 5 enemies that are then killed",
		type: MedalRarity.Normal,
		category: MedalType.Assists,
		sort: 0,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2477555653.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2477555653.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2477555653.png"
		}
	},
	1685043466: {
		name: "Treasure Hunter",
		description: "Mark 3 Power weapons that are picked up by allies",
		type: MedalRarity.Normal,
		category: MedalType.Assists,
		sort: 1,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1685043466.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1685043466.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1685043466.png"
		}
	},
	20397755: {
		name: "Saboteur",
		description: "Destroy 5 enemy vehicles",
		type: MedalRarity.Normal,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/20397755.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/20397755.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/20397755.png"
		}
	},
	1284032216: {
		name: "Wingman",
		description: "Earn 10 kill assists",
		type: MedalRarity.Normal,
		category: MedalType.Assists,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1284032216.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1284032216.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1284032216.png"
		}
	},
	2926348688: {
		name: "Wheelman",
		description: "Earn 5 driver assists",
		type: MedalRarity.Normal,
		category: MedalType.Assists,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2926348688.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2926348688.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2926348688.png"
		}
	},
	3783455472: {
		name: "Gunner",
		description: "Kill 5 enemies with mounted turrets",
		type: MedalRarity.Normal,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3783455472.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3783455472.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3783455472.png"
		}
	},
	3027762381: {
		name: "Driver",
		description: "Kill 5 enemies with ground vehicles",
		type: MedalRarity.Normal,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3027762381.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3027762381.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3027762381.png"
		}
	},
	2593226288: {
		name: "Pilot",
		description: "Kill 5 enemies with aircraft",
		type: MedalRarity.Normal,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2593226288.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2593226288.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2593226288.png"
		}
	},
	2852571933: {
		name: "Rifleman",
		description: "Kill 5 enemies with assault rifles",
		type: MedalRarity.Normal,
		category: MedalType.Weapons,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2852571933.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2852571933.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2852571933.png"
		}
	},
	4086138034: {
		name: "Heavy",
		description: "Kill 5 enemies with detached turrets",
		type: MedalRarity.Normal,
		category: MedalType.Weapons,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4086138034.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4086138034.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/4086138034.png"
		}
	},
	2648272972: {
		name: "Grenadier",
		description: "Kill 5 enemies with grenades",
		type: MedalRarity.Normal,
		category: MedalType.Boom,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2648272972.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2648272972.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2648272972.png"
		}
	},
	1146876011: {
		name: "Bomber",
		description: "Kill 5 enemies with launchers",
		type: MedalRarity.Normal,
		category: MedalType.Boom,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1146876011.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1146876011.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1146876011.png"
		}
	},
	269174970: {
		name: "Boxer",
		description: "Kill 5 enemies with melee",
		type: MedalRarity.Normal,
		category: MedalType.Melee,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/269174970.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/269174970.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/269174970.png"
		}
	},
	1210678802: {
		name: "Warrior",
		description: "Kill 5 enemies with melee weapons",
		type: MedalRarity.Normal,
		category: MedalType.Melee,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1210678802.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1210678802.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1210678802.png"
		}
	},
	1172766553: {
		name: "Gunslinger",
		description: "Kill 5 enemies with pistols",
		type: MedalRarity.Normal,
		category: MedalType.Weapons,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1172766553.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1172766553.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1172766553.png"
		}
	},
	3347922939: {
		name: "Scattergunner",
		description: "Kill 5 enemies with shotguns",
		type: MedalRarity.Normal,
		category: MedalType.Weapons,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3347922939.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3347922939.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3347922939.png"
		}
	},
	2750622016: {
		name: "Breacher",
		description: "Kill 5 enemies with SMG weapons",
		type: MedalRarity.Normal,
		category: MedalType.Weapons,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2750622016.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2750622016.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2750622016.png"
		}
	},
	4277328263: {
		name: "Sharpshooter",
		description: "Kill 5 enemies with sniper rifles",
		type: MedalRarity.Normal,
		category: MedalType.Sniper,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4277328263.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4277328263.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/4277328263.png"
		}
	},
	2758320809: {
		name: "Marksman",
		description: "Kill 5 enemies with tactical rifles",
		type: MedalRarity.Normal,
		category: MedalType.Weapons,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2758320809.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2758320809.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2758320809.png"
		}
	},
	2278023431: {
		name: "Tanker",
		description: "Kill 5 enemies with siege vehicles",
		type: MedalRarity.Normal,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2278023431.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2278023431.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2278023431.png"
		}
	},
	555849395: {
		name: "Bodyguard",
		description: "Save 5 allies by killing their attacker",
		type: MedalRarity.Normal,
		category: MedalType.Skill,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/555849395.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/555849395.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/555849395.png"
		}
	},
	731054446: {
		name: "Skyjack",
		description: "Hijack an enemy aircraft",
		type: MedalRarity.Normal,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/731054446.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/731054446.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/731054446.png"
		}
	},
	1229018603: {
		name: "Dogfight",
		description: "Destroy an enemy aircraft while in one yourself",
		type: MedalRarity.Normal,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1229018603.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1229018603.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1229018603.png"
		}
	},
	2418616582: {
		name: "Harpoon",
		description: "Grapple a distant enemy",
		type: MedalRarity.Normal,
		category: MedalType.Equipment,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2418616582.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2418616582.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2418616582.png"
		}
	},
	3905838030: {
		name: "Cluster Luck",
		description: "Kill 2+ enemies with a single grenade",
		type: MedalRarity.Heoric,
		category: MedalType.Boom,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3905838030.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3905838030.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3905838030.png"
		}
	},
	3655682764: {
		name: "Stick",
		description: "Kill an enemy by sticking them with a Plasma or Spike Grenade",
		type: MedalRarity.Normal,
		category: MedalType.Boom,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3655682764.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3655682764.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3655682764.png"
		}
	},
	1969067783: {
		name: "Chain Reaction",
		description: "Kill an enemy with a shock chain",
		type: MedalRarity.Normal,
		category: MedalType.Weapons,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1969067783.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1969067783.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1969067783.png"
		}
	},
	548533137: {
		name: "Back Smack",
		description: "Kill an enemy by hitting them from behind with melee",
		type: MedalRarity.Normal,
		category: MedalType.Melee,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/548533137.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/548533137.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/548533137.png"
		}
	},
	221693153: {
		name: "Splatter",
		description: "Kill an enemy by hitting them with a vehicle",
		type: MedalRarity.Normal,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/221693153.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/221693153.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/221693153.png"
		}
	},
	2123530881: {
		name: "Reversal",
		description: "Kill an enemy who attacked you first",
		type: MedalRarity.Normal,
		category: MedalType.Skill,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2123530881.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2123530881.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2123530881.png"
		}
	},
	4229934157: {
		name: "Snipe",
		description: "Headshot an enemy with a Power sniper rifle",
		type: MedalRarity.Normal,
		category: MedalType.Sniper,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4229934157.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4229934157.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/4229934157.png"
		}
	},
	3334154676: {
		name: "Guardian Angel",
		description: "Save an ally's life from far away",
		type: MedalRarity.Normal,
		category: MedalType.Skill,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3334154676.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3334154676.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3334154676.png"
		}
	},
	3546244406: {
		name: "Kong",
		description: "Kill an enemy by throwing a Fusion Coil",
		type: MedalRarity.Normal,
		category: MedalType.Boom,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3546244406.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3546244406.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3546244406.png"
		}
	},
	87172902: {
		name: "Odin's Raven",
		description: "Detect 3+ enemies with a single Threat Sensor",
		type: MedalRarity.Normal,
		category: MedalType.Equipment,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/87172902.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/87172902.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/87172902.png"
		}
	},
	1841872491: {
		name: "Tag & Bag",
		description: "Kill 2+ enemies while revealing them with the Threat Sensor",
		type: MedalRarity.Heoric,
		category: MedalType.Equipment,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1841872491.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1841872491.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1841872491.png"
		}
	},
	656245292: {
		name: "Rideshare",
		description: "Deliver the objective carrier to the objective after driving a great distance",
		type: MedalRarity.Heoric,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/656245292.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/656245292.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/656245292.png"
		}
	},
	3876426273: {
		name: "Pancake",
		description: "Kill an enemy by flattening them with the Repulsor",
		type: MedalRarity.Heoric,
		category: MedalType.Equipment,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3876426273.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3876426273.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3876426273.png"
		}
	},
	3114137341: {
		name: "Bulltrue",
		description: "Save yourself or an ally by interrupting an enemy's Energy Sword lunge",
		type: MedalRarity.Heoric,
		category: MedalType.Skill,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3114137341.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3114137341.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3114137341.png"
		}
	},
	2414983178: {
		name: "Bank Shot",
		description: "Kill an enemy with a ricochet",
		type: MedalRarity.Heoric,
		category: MedalType.Weapons,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2414983178.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2414983178.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2414983178.png"
		}
	},
	265478668: {
		name: "Nade Shot",
		description: "Headshot an enemy moments after damaging them with a grenade",
		type: MedalRarity.Heoric,
		category: MedalType.Boom,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/265478668.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/265478668.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/265478668.png"
		}
	},
	1512363953: {
		name: "Perfect",
		description: "Kill an enemy with a precision weapon with peak efficiency",
		type: MedalRarity.Heoric,
		category: MedalType.Skill,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1512363953.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1512363953.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1512363953.png"
		}
	},
	1880789493: {
		name: "Mind the Gap",
		description: "Kill an enemy by sending them to their doom with the Repulsor",
		type: MedalRarity.Heoric,
		category: MedalType.Equipment,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1880789493.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1880789493.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1880789493.png"
		}
	},
	3934547153: {
		name: "Hail Mary",
		description: "Kill an enemy with a grenade from far away",
		type: MedalRarity.Heoric,
		category: MedalType.Boom,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3934547153.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3934547153.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3934547153.png"
		}
	},
	988255960: {
		name: "Fire & Forget",
		description: "Kill an enemy with an M41 SPNKr from far away",
		type: MedalRarity.Heoric,
		category: MedalType.Boom,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/988255960.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/988255960.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/988255960.png"
		}
	},
	4215552487: {
		name: "Ballista",
		description: "Kill an enemy with a Skewer from far away",
		type: MedalRarity.Heoric,
		category: MedalType.Sniper,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4215552487.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4215552487.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/4215552487.png"
		}
	},
	677323068: {
		name: "Death Race",
		description: "Splatter 2+ enemies with a single boost in a Ghost",
		type: MedalRarity.Heoric,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/677323068.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/677323068.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/677323068.png"
		}
	},
	2602963073: {
		name: "No Scope",
		description: "Kill an enemy with a Power sniper rifle without zooming",
		type: MedalRarity.Heoric,
		category: MedalType.Sniper,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2602963073.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2602963073.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2602963073.png"
		}
	},
	1734214473: {
		name: "Whiplash",
		description: "Kill a grappling enemy",
		type: MedalRarity.Heoric,
		category: MedalType.Equipment,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1734214473.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1734214473.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1734214473.png"
		}
	},
	2827657131: {
		name: "Windshield Wiper",
		description: "Kill an enemy attempting to hijack a vehicle",
		type: MedalRarity.Heoric,
		category: MedalType.Skill,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2827657131.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2827657131.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2827657131.png"
		}
	},
	524758914: {
		name: "Boom Block",
		description: "Block an incoming projectile by deploying a Drop Wall",
		type: MedalRarity.Legendary,
		category: MedalType.Equipment,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/524758914.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/524758914.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/524758914.png"
		}
	},
	1646928910: {
		name: "Grand Slam",
		description: "Kill 2+ enemies with a single Gravity Hammer swing",
		type: MedalRarity.Legendary,
		category: MedalType.Weapons,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1646928910.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1646928910.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1646928910.png"
		}
	},
	3059799290: {
		name: "Return to Sender",
		description: "Kill an enemy by deflecting their projectile",
		type: MedalRarity.Legendary,
		category: MedalType.Equipment,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3059799290.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3059799290.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3059799290.png"
		}
	},
	651256911: {
		name: "Interlinked",
		description: "Shock 4 enemies with a single chain",
		type: MedalRarity.Legendary,
		category: MedalType.Weapons,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/651256911.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/651256911.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/651256911.png"
		}
	},
	1623236079: {
		name: "Autopilot Engaged",
		description: "Kill the enemy driver of a moving vehicle with a Power sniper rifle",
		type: MedalRarity.Legendary,
		category: MedalType.Sniper,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1623236079.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1623236079.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1623236079.png"
		}
	},
	3217141618: {
		name: "Achilles Spine",
		description: "Kill an Overshielded enemy by hitting them from behind with melee",
		type: MedalRarity.Legendary,
		category: MedalType.Melee,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3217141618.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3217141618.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3217141618.png"
		}
	},
	670606868: {
		name: "Sneak King",
		description: "Kill a camouflaged enemy by hitting them from behind with melee",
		type: MedalRarity.Legendary,
		category: MedalType.Melee,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/670606868.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/670606868.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/670606868.png"
		}
	},
	4132863117: {
		name: "Pull",
		description: "Kill an enemy with a sniper rifle after they've been launched by a Man Cannon",
		type: MedalRarity.Heoric,
		category: MedalType.Sniper,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4132863117.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4132863117.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/4132863117.png"
		}
	},
	2253222811: {
		name: "Nuclear Football",
		description: "Catch an enemy-thrown Fusion Coil",
		type: MedalRarity.Legendary,
		category: MedalType.Skill,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2253222811.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2253222811.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2253222811.png"
		}
	},
	3160646854: {
		name: "Remote Detonation",
		description: "Kill an enemy by shooting a grenade",
		type: MedalRarity.Mythic,
		category: MedalType.Boom,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3160646854.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3160646854.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3160646854.png"
		}
	},
	1312042926: {
		name: "Quigley",
		description: "Kill 2+ enemies with a single S7 Sniper round",
		type: MedalRarity.Mythic,
		category: MedalType.Sniper,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1312042926.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1312042926.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1312042926.png"
		}
	},
	3085856613: {
		name: "Ninja",
		description: "Kill an enemy by leaping over them and hitting them from behind with melee",
		type: MedalRarity.Mythic,
		category: MedalType.Melee,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3085856613.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3085856613.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3085856613.png"
		}
	},
	3739610597: {
		name: "Flyin' High",
		description: "Achieve a massive jump in a fully-loaded vehicle",
		type: MedalRarity.Normal,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3739610597.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3739610597.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3739610597.png"
		}
	},
	1065136443: {
		name: "Mount Up",
		description: "Assemble a fully-loaded vehicle of 2+ allies after honking the horn",
		type: MedalRarity.Normal,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1065136443.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1065136443.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1065136443.png"
		}
	},
	1445036152: {
		name: "Reclaimer",
		description: "Hijack an enemy vehicle that was once yours",
		type: MedalRarity.Normal,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1445036152.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1445036152.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1445036152.png"
		}
	},
	2625820422: {
		name: "From the Grave",
		description: "Kill an enemy after you die",
		type: MedalRarity.Normal,
		category: MedalType.Skill,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2625820422.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2625820422.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2625820422.png"
		}
	},
	275666139: {
		name: "Special Delivery",
		description: "Kill an enemy with a Man Cannon- or Grav Lift-boosted grenade",
		type: MedalRarity.Normal,
		category: MedalType.Boom,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/275666139.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/275666139.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/275666139.png"
		}
	},
	2861418269: {
		name: "Quick Draw",
		description: "Kill an enemy with a pistol moments after switching to it",
		type: MedalRarity.Normal,
		category: MedalType.Weapons,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2861418269.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2861418269.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2861418269.png"
		}
	},
	3091261182: {
		name: "Last Shot",
		description: "Kill an enemy with your magazine's last round",
		type: MedalRarity.Normal,
		category: MedalType.Weapons,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3091261182.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3091261182.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3091261182.png"
		}
	},
	1331361851: {
		name: "Mounted & Loaded",
		description: "Earn a Double Kill with a stationary turret",
		type: MedalRarity.Heoric,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1331361851.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1331361851.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1331361851.png"
		}
	},
	3583966655: {
		name: "Party's Over",
		description: "Destroy a fully-loaded enemy Razorback",
		type: MedalRarity.Heoric,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3583966655.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3583966655.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3583966655.png"
		}
	},
	1298835518: {
		name: "Ramming Speed",
		description: "Destroy an enemy vehicle by hitting it with your own",
		type: MedalRarity.Heoric,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1298835518.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1298835518.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1298835518.png"
		}
	},
	3588869844: {
		name: "From the Void",
		description: "Kill an enemy through a teleporter",
		type: MedalRarity.Heoric,
		category: MedalType.Skill,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3588869844.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3588869844.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3588869844.png"
		}
	},
	4007438389: {
		name: "Blind Fire",
		description: "Kill a distant enemy by shooting them from inside a Shroud Screen",
		type: MedalRarity.Legendary,
		category: MedalType.Equipment,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4007438389.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4007438389.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/4007438389.png"
		},
	},
	3475540930: {
		name: "Lawnmower",
		description: "Destroy an enemy vehicle with the wheels of a Brute Chopper",
		type: MedalRarity.Heoric,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3475540930.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3475540930.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3475540930.png"
		}
	},
	690125105: {
		name: "Grapple-jack",
		description: "Grapple to and hijack an enemy vehicle",
		type: MedalRarity.Heoric,
		category: MedalType.Equipment,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/690125105.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/690125105.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/690125105.png"
		}
	},
	175594566: {
		name: "Hold This",
		description: "Kill an enemy moments after dropping a weapon using your remaining gun",
		type: MedalRarity.Heoric,
		category: MedalType.Skill,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/175594566.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/175594566.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/175594566.png"
		}
	},
	1169571763: {
		name: "Shot Caller",
		description: "Headshot an enemy moments after Marking them",
		type: MedalRarity.Heoric,
		category: MedalType.Skill,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1169571763.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1169571763.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1169571763.png"
		}
	},
	1283796619: {
		name: "Off the Rack",
		description: "Kill an enemy with a weapon moments after retrieving it from its spawn location",
		type: MedalRarity.Heoric,
		category: MedalType.Weapons,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1283796619.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1283796619.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1283796619.png"
		}
	},
	1176569867: {
		name: "Yard Sale",
		description: "Kill an enemy who had a Power weapon and a full inventory",
		type: MedalRarity.Heoric,
		category: MedalType.Skill,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1176569867.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1176569867.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1176569867.png"
		}
	},
	2019283350: {
		name: "Pineapple Express",
		description: "Kill an enemy with a grenade as a passenger",
		type: MedalRarity.Heoric,
		category: MedalType.Boom,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2019283350.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2019283350.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2019283350.png"
		}
	},
	2396845048: {
		name: "Deadly Catch",
		description: "Grapple a weapon or object to you and immediately kill an enemy with it",
		type: MedalRarity.Legendary,
		category: MedalType.Equipment,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2396845048.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2396845048.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2396845048.png"
		}
	},
	1427176344: {
		name: "360",
		description: "Kill an enemy by shooting them moments after spinning around",
		type: MedalRarity.Legendary,
		category: MedalType.Skill,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1427176344.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1427176344.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1427176344.png"
		}
	},
	2967011722: {
		name: "Street Sweeper",
		description: "Kill an enemy with the CQS48 Bulldog as a passenger",
		type: MedalRarity.Legendary,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2967011722.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2967011722.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2967011722.png"
		}
	},
	197913196: {
		name: "Driveby",
		description: "Earn a Double Kill as a passenger",
		type: MedalRarity.Legendary,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/197913196.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/197913196.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/197913196.png"
		}
	},
	641726424: {
		name: "Combat Evolved",
		description: "Catch a power weapon that was blasted off of a Weapon Pad with a Plasma Grenade",
		type: MedalRarity.Legendary,
		category: MedalType.Skill,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/641726424.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/641726424.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/641726424.png"
		}
	},
	1211820913: {
		name: "Fastball",
		description: "Kill an enemy with the impact from a thrown grenade",
		type: MedalRarity.Mythic,
		category: MedalType.Boom,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1211820913.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1211820913.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1211820913.png"
		}
	},
	1477806194: {
		name: "Counter-Snipe",
		description: "Headshot an enemy while you both are zoomed with Power sniper rifles",
		type: MedalRarity.Heoric,
		category: MedalType.Sniper,
		image_urls: {
		  small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1477806194.png?width=72&height=72",
		  medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1477806194.png?width=128&height=128",
		  large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1477806194.png"
		}
	  },
	  580478179: {
		name: "Hill Guardian",
		description: "Occupy the zone for 30 seconds without leaving in King of the Hill",
		type: MedalRarity.Heoric,
		category: MedalType.KingOfTheHill,
		image_urls: {
		  small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/580478179.png?width=72&height=72",
		  medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/580478179.png?width=128&height=128",
		  large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/580478179.png"
		}
	  },
	1090931685: {
		name: "Monopoly",
		description: "Capture all zones in a set of 3+ without dying in Land Grab",
		type: MedalRarity.Heoric,
		category: MedalType.Zone,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1090931685.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1090931685.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1090931685.png"
		}
	},
	3169118333: {
		name: "Driving Spree",
		description: "Earn 5 Driver Assists without dying",
		type: MedalRarity.Normal,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3169118333.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/3169118333.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/3169118333.png"
		},
	},
	2848470465: {
		name: "Death Cabbie",
		description: "Earn 10 Driver Assists without dying",
		type: MedalRarity.Legendary,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2848470465.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2848470465.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2848470465.png"
		},
	},
	1739996188: {
		name: "Immortal Chauffeur",
		description: "Earn 15 Driver Assists without dying",
		type: MedalRarity.Mythic,
		category: MedalType.Vehicles,
		image_urls: {
			small: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1739996188.png?width=72&height=72",
			medium: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/1739996188.png?width=128&height=128",
			large: "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/1739996188.png"
		},
	},
	394349536: {
		"name": "Clear Reception",
		"description": "Complete an Extraction from Deploy to Completion without the device being Converted",
		type: MedalRarity.Heoric,
		category: MedalType.Extraction,
		"image_urls": {
			"small": "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/394349536.png?width=72&height=72",
			"medium": "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/394349536.png?width=128&height=128",
			"large": "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/394349536.png"
		},
	},
	2964157454: {
		"name": "Call Blocked",
		"description": "Kill an enemy converting the device",
		type: MedalRarity.Normal,
		category: MedalType.Extraction,
		"image_urls": {
			"small": "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2964157454.png?width=72&height=72",
			"medium": "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/2964157454.png?width=128&height=128",
			"large": "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/2964157454.png"
		}
	},
	4285712605: {
		"name": "Hang Up",
		"description": "Kill an enemy deploying the device",
		type: MedalRarity.Normal,
		category: MedalType.Extraction,
		"image_urls": {
			"small": "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4285712605.png?width=72&height=72",
			"medium": "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/render/image/public/assets/games/halo-infinite/metadata/multiplayer/medals/4285712605.png?width=128&height=128",
			"large": "https://etxvqmdrjezgtwgueiar.supabase.co/storage/v1/object/public/assets/games/halo-infinite/metadata/multiplayer/medals/4285712605.png"
		}
	}
};